.List {
    @include has(item) {
        &.disabled {
            opacity: 0.3;
            user-select: none;

            .mdc-list-item__content {
                cursor: default;
            }
        }

        @include is(no-padding) {
            .mdc-list-item__content {
                padding: 0 !important;
            }
        }

        @include has(label) {
            font-size: 1em;
            font-weight: normal;
            margin-bottom: 0;
        }

        @include has(actions) {
            display: none;
        }

        @include is(active) {
            border-left: 4px solid map-get($aw-primary-theme, 500) !important;
        }

        &:hover {
            .List__item__actions {
                display: flex;
            }
        }
    }
}

.Detail-list {
    @include has(label) {
        margin-bottom: 0;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 400;
    }

    @include has(value) {
        p {
            margin-bottom: 0;
        }
    }
}

.Dragdrop-list {
    @include has(item) {
        .cdk-drag-handle {
            cursor: move;
        }
    }
}

.Selection-list {
    @include has(item) {
        &.active {
            background-color: map-get($aw-primary-theme, 500);
            color: #ffffff;

            .mdc-list-item__primary-text {
                color: #ffffff !important;
            }

            .mat-icon {
                color: #ffffff !important;
            }
        }
    }
}
