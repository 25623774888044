@import '../helpers/index.scss';

.Activity-list-item {
    height: auto !important;
    min-height: 60px;

    @include has(title) {
        font-size: 1em;
        font-weight: 400;
    }

    @include has(category) {
        --color: #333333;

        color: var(--color);
    }
}

.Activity {
    --color: #666666;

    cursor: pointer;

    ::ng-deep .mat-mdc-list-item-unscoped-content {
        &::before {
            display: none;
        }
    }

    &:hover {
        background: rgba(0, 0, 0, 0.05);
    }

    @include has(category) {
        color: var(--color);
        text-transform: uppercase;
        font-size: 0.8em !important;
        font-weight: 500 !important;
    }

    @include has(title) {
        font-size: 1em !important;
        color: #000000;
    }

    @include has(date) {
        color: var(--color);
    }
}
