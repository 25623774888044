@import '../helpers/index.scss';

.Page {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 64px);
    position: relative;

    @include is(scrollable) {
        height: 100%;
    }

    @include has(container) {
        background-color: transparent !important;
        flex: 1;
    }

    @include has(sidenav) {
        display: flex;
        flex-direction: column;
        height: 100%;
        flex: 1 0 auto;
        background-color: transparent !important;
        width: 250px;
        overflow: visible !important;
        border-right: none !important;

        @include is(no-scroll) {
            .mat-drawer-inner-container {
                overflow-y: hidden;
            }
        }

        .mat-drawer-inner-container {
            display: flex !important;
            flex-direction: column !important;
            overflow-x: hidden;
        }

        @include is(behind) {
            ::ng-deep .mat-sidenav-content {
                background-color: #eee;
            }

            ::ng-deep .mat-nav-list {
                /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
                /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
                &:not(.ripple-enabled) .mat-list-item-ripple {
                    display: none !important;
                }

                .mat-mdc-list-item {
                    &.disabled {
                        opacity: 0.3;
                        user-select: none;

                        .mdc-list-item__content {
                            cursor: default;
                        }
                    }
                }
            }
        }

        @include has(head) {
            height: 200px;
            min-height: 200px;
            max-height: 200px !important;
            padding: 15px;

            @include has(controls) {
                width: 100%;
                margin-top: 40px;
                margin-bottom: -40px;
            }
            mat-select {
                width: 100%;
                color: #fff;
                /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
                .mat-select-value,
                .mat-select-arrow {
                    color: #fff;
                }
                /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
                .mat-select-underline {
                    background-color: #fff;
                }
            }
        }

        @include has(title) {
            display: flex;
            align-items: center;
            margin: 0;
            color: #fff;

            .mat-icon {
                width: 40px;
                height: 36px;
                font-size: 36px;
            }
        }

        @include has(content) {
            margin-right: -10px;
            overflow-y: auto;
            overflow-x: hidden;
        }
    }

    @include has(main) {
        padding-right: 15px;
        padding-bottom: 15px;
        padding-left: 10px;
        z-index: 50;
    }

    @include has(backdrop) {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 25vh;
        max-height: 200px;
        min-height: 100px;
        background-size: cover;
        display: flex;
        overflow: hidden;

        @include is(static) {
            min-height: 200px;
        }
    }

    @include has(content) {
        display: flex;
        flex-direction: column;
        margin-top: 40px;
        background: #fff;
        overflow-y: auto;
        @include contentShadow();

        @include is(fixed) {
            height: calc(100vh - 215px);
        }
    }

    @include has(tabs) {
        .mat-mdc-tab-link {
            &:hover {
                color: unset;
                text-decoration: unset;
            }
        }
    }

    @include has(navbar) {
        background: #fff;
        border-radius: 5px;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    }
}

@media print {
    // @page {
    //   size: landscape;
    // }

    html,
    body {
        height: 100%;
        overflow: hidden;
        margin: 0;
        padding: 0;
        background: #fff;
    }

    .Sidenav,
    .Page__sidenav {
        display: none !important;
    }

    .Topbar {
        display: none !important;
    }

    .Calendar-wrapper__toolbar {
        display: none !important;
    }

    .mat-drawer-content {
        margin-left: 0 !important;
    }

    .fc-scroller.fc-time-grid-container {
        height: auto !important;
    }

    .fc-event {
        font-size: 10px;
        print-color-adjust: exact !important;
        -webkit-print-color-adjust: exact !important;
    }

    .fc-head {
        print-color-adjust: exact !important;
        -webkit-print-color-adjust: exact !important;
    }
}
