@use 'sass:math';

.text-gray {
    color: #999 !important;
}

.Content-label {
    font-size: 0.8em;
    text-transform: uppercase;
    color: #666;
    margin-bottom: 2px;
}

.hidden-print {
    @media print {
        display: none !important;
    }
}

[hidden] {
    display: none !important;
}

.multi-line {
    white-space: pre-line;
}

.hidden-noprint {
    display: none !important;

    @media print {
        display: initial !important;
    }
}

.scrollable-content {
    position: relative;
    overflow: hidden;

    &::after {
        background: linear-gradient(rgba(0, 0, 0, 0), 65%, rgba(0, 0, 0, 0.4));
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0;
        content: '';
        transition: height 0.2s;
    }

    @include is(shadow) {
        &::after {
            height: 30px;
        }
    }

    @include is(expanded) {
        max-height: none !important;

        &::after {
            display: none;
        }
    }

    @include has(scroll-shadow) {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 100;
        width: 100%;
        height: 10px;
        background: red;
    }
}

@media print {
    body {
        -webkit-print-color-adjust: exact !important;
    }

    .cdk-overlay-backdrop {
        display: none;
    }
    .fullscreen-on-print {
        width: 100%;
        height: 100%;
        min-width: 100%;
        margin-top: 100px;
    }
}
