@import '../helpers/index.scss';

// @page {
//     size: A4 landscape;
// }

.Dialog {
    --minWidth: 500px;
    --minHeight: 400px;
    --width: 500px;
    --height: 500px;

    &.Dialog--resizeable {
        --mat-dialog-container-min-width: var(--minWidth);
        min-height: var(--minHeight);
        resize: both;
        overflow: hidden;
        // .mat-mdc-dialog-container {
        //     resize: both;
        // }

        // &:not(.Dialog--full) {
        //     width: auto !important;
        //     height: auto !important;

        //     .mat-mdc-dialog-container {
        //         min-width: var(--minWidth);
        //         min-height: var(--minHeight);
        //         height: var(--height);
        //         width: var(--width);
        //     }
        // }

        @include is(rtl) {
            direction: rtl;
            // .mat-mdc-dialog-container {
            //     direction: rtl;
            // }
        }
    }

    @include has(header) {
        &.cdk-drag-handle {
            cursor: move;
        }
    }

    @include is(job-evaluation) {
        @media print {
            width: 100vw !important;
            height: 100vh;
            max-width: none !important;
            margin-top: 96px;
        }
    }

    @include is(content-map) {
        min-width: 500px;
        width: 600px;
        height: 700px;
    }
}
