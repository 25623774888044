@use '@angular/material' as mat;

@include mat.all-component-typographies();
@include mat.core();

// $green: map-merge(mat.$teal-palette, (
//   200: map-get($aw-primary-theme, 200),
//   500: map-get($aw-primary-theme, 500),
//   800: map-get($aw-primary-theme, 800),
// ));

$primary: mat.m2-define-palette($aw-primary-theme);
$accent: mat.m2-define-palette(mat.$m2-grey-palette);

$body1: mat.m2-define-typography-level(16px);

$app-theme: mat.m2-define-light-theme(
    (
        color: (
            primary: $primary,
            accent: $accent,
        ),
        typography:
            mat.m2-define-typography-config(
                $font-family: Roboto,
                $body-1: $body1,
            ),
        density: 0,
    )
);

$dark: map-merge(
    mat.$m2-blue-grey-palette,
    (
        500: map-get($aw-dark-theme, primary),
    )
);

$dark-primary: mat.m2-define-palette($dark);
$dark-accent: mat.m2-define-palette(mat.$m2-amber-palette, A200, A100, A400);
$dark-warn: mat.m2-define-palette(mat.$m2-deep-orange-palette);
$dark-theme: mat.m2-define-dark-theme(
    (
        color: (
            primary: $dark-primary,
            accent: $dark-accent,
            warn: $dark-warn,
        ),
    )
);

@include mat.all-component-themes($app-theme);

.Sidenav {
    --mat-sidenav-container-background-color: var(--color-secondary) !important;

    @include mat.all-component-colors($dark-theme);
}
