@import '../../../styles/helpers/index.scss';

.Dialog {
    @include has(header) {
        height: auto;
        min-height: 64px;
        // padding: 12px;

        .mat-mdc-icon-button {
            color: #fff;
        }

        @include is(light) {
            .mat-mdc-icon-button {
                color: #000000;
            }
        }
    }
    @include has(title) {
        display: flex !important;
        align-items: center;
        color: #fff !important;
        line-height: 1.5rem !important;
        padding: 0 !important;
        white-space: pre-line;

        mat-icon {
            margin-right: 5px;
        }
    }

    @include has(subtitle) {
        color: #fff;
        font-size: 0.8rem !important;
        font-weight: 300 !important;
        line-height: 0.8rem !important;
    }

    @include has(content) {
        &.mdc-dialog__content.mat-mdc-dialog-content {
            padding: 0;
        }

        --mdc-dialog-supporting-text-color: #000000;
    }

    @include has(footer) {
        justify-content: flex-end !important;
        align-items: center;

        @include is(complex) {
            justify-content: space-between !important;
        }

        @include has(text) {
            display: flex;
            align-items: center;
            margin: 0;
            font-size: 0.8em;
            color: #666;

            .material-icons {
                font-size: 16px;
                height: 16px;
            }
        }
    }

    @include has(form) {
        width: 100%;
        box-sizing: border-box;
        padding: 15px;

        .mat-mdc-form-field {
            width: 100%;
        }
    }

    @include has(inner) {
        width: 100%;
        box-sizing: border-box;
        padding: 15px;
    }

    @include has(text) {
        font-size: 0.8em;
    }

    @media print {
        .mat-mdc-dialog-content {
            max-height: none !important;
        }
    }
}

.mat-mdc-dialog-surface {
    overflow-x: hidden !important;
}
