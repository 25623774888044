.Snackbar {
    @include is(error) {
        .mdc-snackbar__surface {
            background-color: $error !important;
        }
    }

    @include is(success) {
        .mdc-snackbar__surface {
            background-color: map-get($aw-primary-theme, 800) !important;
        }
    }
}
