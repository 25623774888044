/* You can add global styles to this file, and also import other style files */
@import '@ali-hm/angular-tree-component/css/angular-tree-component.css';
@import 'vanilla-jsoneditor/themes/jse-theme-dark.css';

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900');

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import 'app/styles/helpers/variables';
@import 'app/styles/helpers/theme';
@import 'app/styles/config.scss';

html,
body {
    font-family: 'Roboto', sans-serif;
    min-height: 100%;
    height: 100%;
    margin: 0;
}

div {
    box-sizing: border-box;
}

// This needs to be here because it has no effect otherwise. Yep, thats how it is
.Message--mine .Message__text a {
    color: #fff;
}

.Message__text a {
    font-weight: 500;
}

@layer base {
    ul,
    ol {
        list-style: revert;
        padding: revert;
        @apply mb-3;
    }

    li {
        @apply py-1;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: revert;
    }

    h1 {
        @apply text-4xl;
    }
    h2 {
        @apply text-3xl;
    }
    h3 {
        @apply text-2xl;
    }
    h4 {
        @apply text-xl;
    }
    h5 {
        @apply text-base;
    }
    h6 {
        @apply text-sm;
    }
}
