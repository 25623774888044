@import '../helpers/index.scss';

.Calendar {
    ::ng-deep .cal-month-view {
        .cal-days .cal-cell-row {
            position: relative;
        }

        .cal-cell-top {
            min-height: unset;
        }

        .cal-day-cell {
            cursor: pointer;

            &.cal-day-selected {
                background-color: map-get($aw-primary-theme, 500);
                color: #ffffff;
                position: relative;

                .cal-cell-top {
                    filter: blur(5px);
                }

                &:hover {
                    background-color: darken(map-get($aw-primary-theme, 500), 10%);
                }
            }

            &.cal-day-inrange {
                background-color: lighten(map-get($aw-primary-theme, 200), 0%);
            }

            .cal-cell-lunch {
                white-space: pre-wrap;
                font-size: 0.8rem;
                color: #666;
                max-height: 100px;
                overflow: hidden;
            }

            &.cal-out-month {
                .cal-cell-price {
                    opacity: 0.3;
                }
            }

            .cal-cell-hoveroverlay {
                width: 100%;
                height: 100%;
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                animation-duration: 0.3s;

                .mat-icon {
                    font-size: 40px;
                    width: 40px;
                    height: 40px;
                }
            }
        }
    }

    @include is(hasrange) {
        ::ng-deep .cal-month-view {
            .cal-day-cell {
                &.cal-day-selected {
                    .cal-cell-top {
                        filter: none;
                    }

                    .cal-cell-overlay {
                        display: none;
                    }
                }
            }
        }
    }

    @include is(rangeselecting) {
        ::ng-deep .cal-month-view {
            .cal-day-cell {
                &:hover {
                    background-color: lighten(map-get($aw-primary-theme, 200), 0%);
                }
            }
        }
    }

    @include has(week) {
        font-size: 0.8rem;
        color: #999;
        position: absolute;
    }
}

.Timeline-calendar {
    .fc-timeline-event {
        opacity: 0.65;
        height: 65px;
        border-radius: 5px !important;
        cursor: pointer;
        transition: opacity 0.3s;

        &:hover {
            opacity: 0.8;
        }

        .fc-event-title {
            font-size: 1.2em;
            font-weight: 600;
        }
    }

    .fc-timeline-slot-cushion {
        color: var(--color-secondary);
    }
}
