.Sidenav {
    width: 250px !important;
    overflow-y: hidden !important;

    --mat-badge-container-overlap-offset: -5px -9px;
}

.Org-list {
    top: 65px;
    position: absolute;
    left: -250px;
    width: 250px;
    background: lighten(map-get($aw-dark-theme, primary), 10%) !important;
    color: #fff;

    @include has(item) {
        color: #fff;

        &[disabled] {
            color: #aaa;
        }

        @include has(path) {
            margin: 0;
            line-height: 16px;
            font-size: 12px;

            span {
                &:after {
                    content: ' > ';
                }

                &:last-child {
                    &:after {
                        content: '';
                    }
                }
            }
        }

        @include has(title) {
            margin: 0;
            line-height: 16px;
        }
    }
}
