body {
    --table-scroll-height: 300px;
}

button[block] {
    width: 100%;
}

*[contenteditable='true'] {
    display: inline-block;
    cursor: pointer;
    min-width: 10px;
}

a {
    color: map-get($aw-primary-theme, 500);
}

.text-grey {
    color: #999;
}

.text-capitalize-first {
    text-transform: lowercase;
    &:first-letter {
        text-transform: capitalize;
    }
}

.text-small {
    font-size: 0.8em;
}

input[type='time'] {
    height: 18px;
}

.mat-drawer-content {
    overflow: hidden !important;
}

.mat-mdc-raised-button.mat-primary,
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
    color: #fff;
}

.ngx-datatable {
    .datatable-scroll {
        overflow-x: hidden !important;
    }

    .datatable-body {
        .datatable-scroll {
            max-height: calc(100vh - var(--table-scroll-height));
            overflow-y: auto;
            margin-bottom: -7px;
        }
    }

    .datatable-footer {
        overflow: hidden !important;
        .datatable-pager {
            display: block !important;
        }
    }
}
.mat-mdc-dialog-container {
    padding: 0 !important;
    overflow: hidden !important;
}

.mat-mdc-dialog-content {
    overflow-x: hidden !important;
}

.mat-mdc-dialog-actions {
    padding: 12px !important;
}

.mat-mdc-dialog-actions:last-child {
    margin-bottom: 0 !important;
}

button:focus {
    outline: none !important;
}

.mat-mdc-list-item.gu-mirror {
    .mdc-list-item__content {
        padding: 0 16px;
    }
}

.drag-handle {
    cursor: move !important;
}

.mat-mdc-form-field.auto-width {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-infix {
        width: auto;
    }
}

.Date-field {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-infix {
        width: 130px;
        .mat-datepicker-toggle {
            position: absolute;
            top: 0;
            right: 0;
        }
    }
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-label-content {
    display: flex;
    align-items: center;

    .mat-icon {
        margin-right: 5px;
    }
}

.dl-styled {
    dl:after {
        content: '';
        display: table;
        clear: both;
    }
    dd {
        padding: 0.5em 0;
    }
    dl {
        width: 100%;
        box-sizing: border-box;
    }
    dt,
    dd {
        display: inline-block;
        width: 30%;
        box-sizing: border-box;
    }
    dt {
        text-align: right;
        font-weight: bold;
        clear: left;
        float: left;
    }
    dd {
        width: 70%;
        padding-left: 1em;
        clear: right;
        margin: 0;
    }
    dd + dd {
        float: right;
        clear: both;
    }
    dt:first-child {
        padding-top: 0.5em;
    }
    dd + dt {
        clear: both;
        padding-top: 0.5em;
    }
    dt + dt {
        width: 100%;
        float: none;
        padding: 0 70% 0 0;
    }
    dt + dt + dd {
        margin-top: -2em;
    }
    dt + dt + dd + dt {
        margin-top: 2em;
    }
}

.fr-popup {
    .fr-command.fr-btn {
        float: left !important;
        display: flex !important;
        justify-content: center;
        align-items: center;
        min-width: 40px !important;

        &.fr-hidden {
            display: none !important;
        }

        .mat-icon {
            font-size: 20px;
            width: 20px;
            height: 20px;
        }
    }
}

.cdk-drag {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/* Animate an item that has been dropped. */
.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.Select {
    @include is(fluid-width) {
        max-width: 750px !important;
        /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
        .mat-option-text {
            white-space: pre-wrap !important;
            line-height: 1.2em !important;
        }
    }
}

.Dropdown-menu {
    @include is(auto-width) {
        max-width: none;
    }
}

.ck-content p {
    @apply mb-3;
}

mat-slider {
    &.mat-slider-disabled,
    &.mat-slider-visible-thumb {
        margin-top: 15px;
        /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
        /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
        .mat-slider-thumb-label {
            display: flex;
            transform: rotate(45deg);
            border-radius: 50% 50% 0;
            /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
            .mat-slider-thumb-label-text {
                opacity: 1;
                color: #fff;
            }
        }
    }
}

@media print {
    .mat-mdc-dialog-container {
        overflow: auto !important;
    }
}

.tooltip-multi-row {
    white-space: pre-wrap;

    .mdc-tooltip__surface {
        text-align: left;
    }
}

.mat-mdc-menu-panel {
    .mat-mdc-menu-item {
        .mdc-list-item__primary-text {
            font-size: 14px !important;
        }
    }

    &.auto-width {
        max-width: none !important;
    }

    &.no-content-padding {
        .mat-mdc-menu-content {
            padding: 0 !important;
        }
    }

    &.no-scroll {
        .mat-mdc-menu-content {
            height: 100%;
        }
    }
}

.mat-mdc-unelevated-button.mat-primary {
    --mdc-filled-button-label-text-color: var(--color-on-primary);
}

.mat-mdc-unelevated-button.mat-accent {
    --mdc-filled-button-label-text-color: var(--color-on-secondary);
}

.mat-mdc-raised-button.mat-primary {
    --mdc-protected-button-label-text-color: var(--color-on-primary);
}

.mat-mdc-raised-button.mat-accent {
    --mdc-protected-button-label-text-color: var(--color-on-secondary);
}

.mat-mdc-checkbox.mat-primary .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
    color: var(--color-on-primary);
}

.mdc-list-group__subheader {
    font-size: 14px;
    color: #666;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start:not(.default) {
    color: var(--secondary) !important;
    margin-right: 16px !important;
}

.mdc-icon-button.mat-mdc-icon-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-floating-label {
    color: rgba(0, 0, 0, 0.8) !important;
}

.mat-mdc-optgroup-label {
    text-transform: uppercase;
    font-size: 12px;
    color: #666;
}

.mat-badge-content {
    top: -7px !important;
}

/* clears the ‘X’ from Internet Explorer */
input[type='search']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}
input[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}
/* clears the ‘X’ from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
    display: none;
}
.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
    border-right: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    &.mb-0 {
        margin-bottom: 0 !important;
    }
}

.Icon-button {
    @include is(sm) {
        width: 28px !important;
        height: 28px !important;
        padding: 0 !important;

        mat-icon {
            font-size: 1rem !important;
            line-height: 1.5rem !important;
        }

        .mat-mdc-button-touch-target {
            width: 28px !important;
            height: 28px !important;
        }
    }
}

textarea.cdk-textarea-autosize {
    line-height: 1.5em !important;
    // &:not([ng-reflect-max-rows]) {
    //     overflow: hidden !important;
    // }
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
*::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
}

/* background of the scrollbar except button or resizer */
*::-webkit-scrollbar-track {
    background-color: #fff;
}

/* scrollbar itself */
*::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    min-height: 40px;
    border: 4px solid #fff;

    &:hover {
        background-color: #858585;
    }
}

/* set button(top and bottom of the scrollbar) */
*::-webkit-scrollbar-button {
    display: none;
}

.ngx-datatable .datatable-body {
    overflow-y: hidden !important;
}

.mdc-list-item__primary-text {
    padding: 3px 0 !important;
}

.mat-expansion-indicator::after {
    border-color: var(--color-secondary);
}
button.button--expanded {
    .mdc-button__label {
        width: 100%;
    }
}
