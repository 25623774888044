@import '../helpers/index.scss';

.Topbar {
    @include has(menu) {
        position: absolute;
        width: 220px;
        right: 0;

        @include has(badge) {
            padding: 2px 10px;
            margin-top: 7px;
            height: 30px;
            line-height: 30px;
            float: right;
            background-color: map-get($aw-primary-theme, 500);
            color: #fff;
            border-radius: 100%;
        }
    }
}
