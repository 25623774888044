@import '../helpers/index.scss';

.Tag-list {
    @include has(tag) {
        color: #fff !important;
    }
}

.Tag-input {
    .ng2-tag-input {
        @include is(focused) {
            border-color: map-get($aw-primary-theme, 500) !important;
        }

        tag {
            &:active,
            &:focus {
                background: map-get($aw-primary-theme, 500) !important;
            }

            font-size: 0.8em;
        }
    }

    @include has(tag) {
        @include is(deleted) {
            border: 2px solid $error !important;
        }
    }
}

.Badge {
    --color: #333333;

    padding: 4px 8px;
    border-radius: 20px;
    background-color: var(--color);
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;

    @include is(success) {
        --color: var(--color-primary);
    }

    @include is(small) {
        padding: 1px 6px;
        font-size: 11px;
        font-weight: 500;
    }
}
