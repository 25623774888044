@import '../helpers/index.scss';

.Icon {
    @include is(success) {
        color: map-get($aw-primary-theme, 500);
    }

    @include is(error) {
        color: $error;
    }

    @include is(disabled) {
        color: #999;
    }

    @include is(sm) {
        --size: 16px;
        width: var(--size) !important;
        height: var(--size) !important;
        font-size: var(--size) !important;
    }

    @include is(xs) {
        --size: 14px;
        font-size: var(--size);
        width: var(--size) !important;
        height: var(--size) !important;
        line-height: 16px !important;
    }
}
