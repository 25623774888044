$aw-dark-theme: (
    primary: #34495e,
);

$aw-primary-theme: (
    50: #e8f4ed,
    100: #c8e4d2,
    200: #a6d2b6,
    300: #84c19b,
    400: #6cb487,
    500: #55a873,
    600: #4d9968,
    700: #45875c,
    800: #3f7651,
    900: #34563f,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
    ),
);

// $aw-primary-theme: (
//     50: #dff1e7,
//     100: #b3dcc4,
//     200: #90cfab,
//     300: #82c59d,
//     400: #65b186,
//     500: #00a05f,
//     600: #028450,
//     700: #00823c,
//     800: #006026,
//     900: #004313,
//     contrast: (
//         50: #000000,
//         100: #000000,
//         200: #000000,
//         300: #000000,
//         400: #ffffff,
//         500: #ffffff,
//         600: #ffffff,
//         700: #ffffff,
//         800: #ffffff,
//         900: #ffffff,
//     )
// );

$error: #f44336;

$layout-breakpoint-xs: 600px !default;
$layout-breakpoint-sm: 960px !default;
$layout-breakpoint-md: 1280px !default;
$layout-breakpoint-lg: 1920px !default;

$bp-small: '(max-width: 600px)';
$bp-medium: '(max-width: 960px)';
$bp-large: '(max-width: 1280px)';
$bp-xlarge: '(max-width: 1920px)';

:root {
    --color-primary: #55a873;
    --color-primary-tint: #65c086;
    --color-primary-shade: #398756;
    --color-on-primary: #ffffff;

    --color-secondary: #34495e;
    --color-secondary-tint: #485b6e;
    --color-secondary-shade: #2e4053;
    --color-on-secondary: #ffffff;

    --color-warning: #e58d29;
    --color-warning-tint: #e68a1a;
    --color-warning-shade: #90560c;
    --color-on-warning: #ffffff;

    --color-danger: #e74c3c;
    --color-danger-tint: #f7655c;
    --color-danger-shade: #a53a2b;
    --color-on-danger: #ffffff;

    --color-blue: #3498db;
    --color-blue-tint: #4aa3df;
    --color-blue-shade: #2b7da8;
    --color-on-blue: #ffffff;

    --color-background: #f5f5f5;
    --color-background-tint: #ffffff;
    --color-background-shade: #e0e0e0;
    --color-on-background: #000000;

    --mat-menu-item-label-text-size: 14px;
    --mat-expansion-header-indicator-color: var(--color-secondary-shade);
    --mat-sidenav-container-width: 250px;
    --mat-badge-container-overlap-offset: -5px -9px;
}
