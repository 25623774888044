@import '../helpers/index.scss';

.Alert {
    --background: #eeeeee;
    --color: #000000;
    --borderColor: #cccccc;

    background-color: var(--background);
    color: var(--color);
    padding: 16px;
    border: 1px solid var(--borderColor);
    margin-bottom: 16px;
    border-radius: 5px;

    @include is(info) {
        --background: #91bbff;
        --color: #ffffff;
        --borderColor: #5c9aff;
    }
}
