@mixin has($elements...) {
    @each $element in $elements {
        @at-root #{&}__#{$element} {
            @content;
        }
    }
}

@mixin hasFrom($root, $elements...) {
    @each $element in $elements {
        .#{$root}__#{$element} {
            @content;
        }
    }
}

@mixin is($states...) {
    @each $state in $states {
        @at-root #{&}--#{$state} {
            @content;
        }
    }
}

@mixin bp($point) {
    @if $point == small {
        @media #{$bp-small} {
            @content;
        }
    }

    @if $point == medium {
        @media #{$bp-medium} {
            @content;
        }
    }

    @if $point == large {
        @media #{$bp-large} {
            @content;
        }
    }

    @if $point == xlarge {
        @media #{$bp-xlarge} {
            @content;
        }
    }
}

@mixin contentShadow() {
    box-shadow:
        0 4px 5px -2px rgba(0, 0, 0, 0.2),
        0 7px 10px 1px rgba(0, 0, 0, 0.14),
        0 2px 16px 1px rgba(0, 0, 0, 0.12);
}

@mixin ellipsis($lines: 1) {
    display: -webkit-box !important;
    line-clamp: $lines !important;
    -webkit-line-clamp: $lines !important;
    box-orient: vertical !important;
    -webkit-box-orient: vertical !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
}
